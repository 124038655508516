'use strict';
import Popup from '../popup/popup';
import Swiper from 'swiper';

export default class PopupSlider {
  constructor({
    popupCssClass = '.popup--slider', //css класс попапа
    linkOpenPopupCssClass, //css класс ссылки открывающая попап
    listPreviewCssClass, //css класс списка превьюх
    callback //калбек вызываемый после закрытия попапа
  }) {

    this.name = 'popup-slider';
    this.linkOpenPopupCssClass = linkOpenPopupCssClass;
    this.previewListInner = document.querySelector(listPreviewCssClass);
    this.callback = callback;
    this.popupElement = document.querySelector(popupCssClass);
    this.popupContent = this.popupElement.querySelector('.popup__content');
    this.activeLink = null;
    this.listItemsArr = null;
    this.activeIndex = null;
    this.imgArr = null;
    this.sliderElement = null;
    this.slider = null;

    this.destroySlider = this.destroySlider.bind(this);
  }

  addHtmlForSliderToPopup() {
    let htmlSliderItems = ``;
    this.imgArr.forEach(element => {
      const srcImg = element.getAttribute('src');
      const altImg = element.getAttribute('alt');
      htmlSliderItems += `
        <div class="popup-slider__list-item">
          <div class="popup-slider__inner-img" style="background-image:url(${srcImg})">
            <img class="popup-slider__img" src="${srcImg}" alt="${altImg}">
          </div>
        </div>
      `;
    });

    this.popupContent.innerHTML = `
        <section class="popup-slider">
          <div class="popup-slider__list-images">
            ${htmlSliderItems}
          </div>
        </section>
      `;
  }

  initSlider() {
    this.addHtmlForSliderToPopup();
    setTimeout(() => {
      this.sliderElement = this.popupElement.querySelector('.popup-slider');
      this.slider = new Swiper(this.sliderElement, {
        initialSlide: this.activeIndex,
        direction: 'horizontal',
        spaceBetween: 0,
        slidesPerView: 1,
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        containerModifierClass: this.containerModifierClass,
        slideClass: 'popup-slider__list-item',
        slideActiveClass: 'popup-slider__list-item--active',
        slideVisibleClass: 'popup-slider__list-item--visible',
        slideDuplicateClass: 'popup-slider__list-item--duplicate-class',
        slideNextClass: 'popup-slider__list-item--next-class',
        slideDuplicateNextClass: 'popup-slider__list-item--duplicate-next-class',
        slidePrevClass: 'popup-slider__list-item--prev-class',
        slideDuplicatePrevClass: 'popup-slider__list-item--duplicate-prev-class',
        wrapperClass: 'popup-slider__list-images'
      });
    }, 10);
  }

  destroySlider() {
    this.slider.destroy();
    this.popupContent.innerHTML = ``;
    if (this.callback) this.callback();
  }

  getActiveIndex(element) {
    this.activeIndex = this.listItemsArr.indexOf(element.parentElement);
  }

  getItemsPreviewList() {
    if (!this.previewListInner) return;
    this.listItemsArr = [...this.previewListInner.children];
  }

  getImgElements() {
    if (!this.previewListInner) return;
    this.imgArr = [...this.previewListInner.querySelectorAll('img')];
  }

  addClickHandler() {
    document.addEventListener('click', (evt, self) => {
      if (evt.target.closest(this.linkOpenPopupCssClass)) {
        evt.preventDefault();
        this.activeLink = evt.target.closest(this.linkOpenPopupCssClass);
        this.getActiveIndex(this.activeLink);
        this.initSlider();
        this.popupInstance.openPopup()
      }
    })
  }

  openPopup() {
    if (!this.popupElement) return;
    this.popupInstance = new Popup({
      popupElement: this.popupElement,
      callbackAfterClose: this.destroySlider,
      isNotClosePopupAfterClickBackground: true,
    });
  }

  init() {
    this.addClickHandler();
    this.getItemsPreviewList();
    this.getImgElements();
    this.openPopup();
  }
}
