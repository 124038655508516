'use strict';


const DefaultClass = {
  page: "page",
  pageStatic: "page--static",
  header: "header",
  headerSearch: "header__search",
  headerSearchOpen: "header__search--open",
  headerSearchBtn: "header__search-button",
  headerSearchBtnOpen: "header__search-button--open",
  headerSearchBtnClose: "header__search-button--close",
  headerSearchForm: "header__form-search"
};

export default class Header {
  constructor() {
    this.page = document.querySelector(`.${DefaultClass.page}`);
    this.header = document.querySelector(`.${DefaultClass.header}`);
    this.headerSearch = document.querySelector(`.${DefaultClass.headerSearch}`);
    this.headerSearchForm = document.querySelector(`.${DefaultClass.headerSearchForm}`);
    // console.log('%s module', this.name.toLowerCase());
    this.closeSearchByClick = this.closeSearchByClick.bind(this);
    this.closeSearchByBtn = this.closeSearchByBtn.bind(this);
    this.init();
  }

  openHeader() {
    this.header.addEventListener("click", evt => {
      if (!evt.target.closest(`.${DefaultClass.headerSearchBtnOpen}`)) return;
      this.addClass(this.headerSearch, DefaultClass.headerSearchOpen);
      this.addClass(this.page, DefaultClass.pageStatic);
      this.headerSearch.addEventListener("click", this.closeSearchByClick);
      document.addEventListener("keydown", this.closeSearchByBtn);
    });
  }

  closeHeader() {
    this.header.addEventListener("click", evt => {
      if (!evt.target.closest(`.${DefaultClass.headerSearchBtnClose}`)) return;
      this.removeClass(this.headerSearch, DefaultClass.headerSearchOpen);
      this.removeClass(this.page, DefaultClass.pageStatic);
      this.headerSearch.removeEventListener("click", this.closeSearchByClick);
      document.removeEventListener("keydown", this.closeSearchByBtn);
    });
  }

  closeSearchByClick(evt) {
    if (!evt.target.classList.contains(DefaultClass.headerSearch)) return;
    this.removeClass(this.headerSearch, DefaultClass.headerSearchOpen);
    this.removeClass(this.page, DefaultClass.pageStatic);
  }

  closeSearchByBtn(evt) {
    if (evt.keyCode !== 27) return;
    this.removeClass(this.headerSearch, DefaultClass.headerSearchOpen);
    this.removeClass(this.page, DefaultClass.pageStatic);
  }

  addClass(item, activeClass) {
    item.classList.add(activeClass);
  }

  removeClass(item, activeClass) {
    item.classList.remove(activeClass);
  }

  onScrollMainPage() {
    if (!document.querySelector('.main-page')) return;
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 360) {
        this.header.classList.add('header--colored');
      } else {
        this.header.classList.remove('header--colored');
      }
    });
  }

  init() {
    if (!this.header) return;
    this.openHeader();
    this.closeHeader();
    if (window.innerWidth > 768) return;
    this.onScrollMainPage();
  }
}
