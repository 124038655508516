'use strict';

import IMask from 'imask';

export default class Forms {
  constructor() {
    this.name = 'forms';
    this.phoneInputs = document.querySelectorAll('.input--phone');
    this.init();
  }

  _initPhoneInputs() {
    this.phoneInputs.forEach(input => this._initMask(input));
  }

  _initMask(item) {
    let maskOptions = {
      mask: '+{7}(000)000-00-00'
    };
    let mask = IMask(item, maskOptions);
  }

  init() {
    this._initPhoneInputs();
  }

}
