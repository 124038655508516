'use strict';

export default class ViewPassword {
  constructor({containerCssClass ='.input-inner'} = {}) {
    // this.name = 'view-password';
    // console.log('%s module', this.name.toLowerCase());
    this.containerCssClass = containerCssClass;
    this.container = document.querySelector(containerCssClass);
    this.cssClassToggleButton = '.view-password';
    this.addHandler();
  }

  togglePassword(toggleButton) {
    const inputPassword = toggleButton.parentElement.querySelector('.input--password');
    const type = inputPassword.getAttribute('type');
    if(type === 'password') {
      inputPassword.setAttribute('type','text');
    } else {
      inputPassword.setAttribute('type','password');
    }
  }

  addHandler() {
    if(!this.container) return;
    this.container.addEventListener('click', evt => {
      if(evt.target.closest(this.cssClassToggleButton)) {
        evt.preventDefault();
        const toggleButton = evt.target.closest(this.cssClassToggleButton);
        this.togglePassword(toggleButton);
      }
    })
  }
}
