'use strict';


export default class Delivery {
  constructor() {
    this.name = 'delivery';
    console.log('%s module', this.name.toLowerCase());
    this.deliveryCssClass = '.delivery';
    this.deliveryContainer = document.querySelector(this.deliveryCssClass);
    this.deliveryListElement = document.querySelector('.delivery__list');
    this.itemCssClass = 'delivery__item';
    this.inputChangeCssClass = '.delivery__input';
    this.textAddressCssClass = '.delivery__text';
    this.addAddressButtonCssClass = '.delivery__add-address';
    this.deleteAddressButtonCssClass = '.delivery__del-btn';
    this.changeButtonCssClass = '.delivery__edit-btn';
    this.deliveryInputAddress = '.delivery__input-address';
    this.qtyItems = 0;
    this.init();
  }

  getIdItem(){
    this.qtyItems = [...this.deliveryListElement.querySelectorAll('.delivery__item')].length + 1;
    return 'address-' + this.qtyItems;
  }

  getTemplate(text) {
    const newId = this.getIdItem();
    const innerHtmlItem =  `
    <p class="delivery__text">${text}</p>
    <p class="delivery__edit-input">
      <input placeholder="" value="${text}" name="${newId}" id="${newId}" class="input input--text delivery__input">
      <span class="delivery__validation-error">Укажите адрес доставки</span>
    </p>
    <p class="delivery__control-edit">
      <button class="link link--third delivery__edit-btn" type="button">Изменить</button>
      <button class="link link--third delivery__del-btn" type="button">Удалить</button>
    </p>
    `;

    const item = document.createElement('li');
    item.classList.add('delivery__item');
    item.innerHTML = innerHtmlItem;

    return item;
  }

  clearAddAddressInput(inputAddAddress) {
    inputAddAddress.setAttribute('value', '');
    inputAddAddress.value = '';
  }

  isValidate(input) {
    if(input.value.length < 1) {
      input.classList.add('input--error');
      return false;
    }
    return true;
  }

  resetValidation(input) {
    input.classList.remove('input--error')
  }

  addAddressItem(evt) {
    const deliveryContainer = evt.target.closest(this.deliveryCssClass);
    const inputAddAddress = deliveryContainer.querySelector(this.deliveryInputAddress);
    const inputValue = inputAddAddress.value;

    if( this.isValidate(inputAddAddress)) {
      this.deliveryListElement.appendChild(this.getTemplate(inputValue));
      this.clearAddAddressInput(inputAddAddress);
    }
  }

  addAddressHandler() {
    this.deliveryContainer.addEventListener('click', evt => {
      if (evt.target.closest(this.addAddressButtonCssClass)) {
        evt.preventDefault();
        this.addAddressItem(evt);
      }
    });

    this.deliveryContainer.addEventListener('input', evt => {
      if (evt.target.closest(this.deliveryInputAddress)) {
        this.resetValidation(evt.target.closest(this.deliveryInputAddress));
      }
    })
  }

  keyPressEnterHandlerAfterAddAddress() {
    this.deliveryContainer.addEventListener('keypress', evt => {
      if(evt.target.closest(this.deliveryInputAddress) && evt.keyCode === 13) {
        evt.preventDefault();
        this.addAddressItem(evt);
      }
    })
  }

  keyKeydownEscapeHandlerAfterAddAddress() {
    this.deliveryContainer.addEventListener('keydown', evt => {
      if(evt.target.closest(this.deliveryInputAddress) && evt.keyCode === 27) {
        evt.preventDefault();
        const deliveryContainer = evt.target.closest(this.deliveryCssClass);
        const inputAddAddress = deliveryContainer.querySelector(this.deliveryInputAddress);
        this.clearAddAddressInput(inputAddAddress);
      }
    })
  }

  keyKeydownEscapeHandlerAfterEditAddress() {
    this.deliveryContainer.addEventListener('keydown', evt => {
      if(evt.target.closest(this.inputChangeCssClass) && evt.keyCode === 27) {
        evt.preventDefault();
        const itemElement = evt.target.closest('.' + this.itemCssClass);
        itemElement.classList.toggle(this.itemCssClass + '--edit');
      }
    })
  }

  keyPressEnterHandlerAfterEditAddress() {
    this.deliveryContainer.addEventListener('keypress', evt => {
      if(evt.target.closest(this.inputChangeCssClass) && evt.keyCode === 13) {
        evt.preventDefault();
        const itemElement = evt.target.closest('.' + this.itemCssClass);
        itemElement.classList.toggle(this.itemCssClass + '--edit');
        this.changeAddressItem(evt);
      }
    })
  }

  changeAddressItem(evt) {
    const inputElement = evt.target.closest(this.inputChangeCssClass);
    const inputValue = inputElement.value;
    const itemElement = inputElement.closest('.' + this.itemCssClass);
    this.resetValidation(inputElement);


    if(this.isValidate(inputElement)) {
      const itemAddressElement = itemElement.querySelector(this.textAddressCssClass);
      itemAddressElement.textContent = inputValue;
    }

  }

  deleteAddress() {
    this.deliveryContainer.addEventListener('click', evt => {
      if(evt.target.closest(this.deleteAddressButtonCssClass)) {
        evt.preventDefault();
        const itemElement = evt.target.closest('.' + this.itemCssClass);
        itemElement.querySelector(this.inputChangeCssClass).setAttribute('value', 'delete');
        itemElement.style.display = 'none';
      }
    });
  }

  changeAddressHandler() {
    this.deliveryContainer.addEventListener('input', evt => {
      if(evt.target.closest(this.inputChangeCssClass)) {
        this.changeAddressItem(evt);
      }
    })
  }

  addTextAddressToInputEdit(input,text) {
    input.value = text;
    input.setAttribute('value', text);
  }


  clickChangeHandlerButton() {
    this.deliveryContainer.addEventListener('mousedown', evt => {
      if(evt.target.closest(this.changeButtonCssClass)) {
        evt.preventDefault();
        const changeButtonElement = evt.target.closest(this.changeButtonCssClass);
        const itemElement = changeButtonElement.closest('.' + this.itemCssClass);
        const inputElement = itemElement.querySelector(this.inputChangeCssClass);
        this.resetValidation(inputElement);
        const textAddress = itemElement.querySelector(this.textAddressCssClass).textContent;
        this.addTextAddressToInputEdit(inputElement,textAddress);

        itemElement.classList.toggle(this.itemCssClass + '--edit');

      }
    })
  }

  addAddress(){
    this.addAddressHandler();
    this.keyKeydownEscapeHandlerAfterAddAddress();
    this.keyPressEnterHandlerAfterAddAddress();
  }

  changeAddress() {
    this.changeAddressHandler();
    this.keyPressEnterHandlerAfterEditAddress();
    this.keyKeydownEscapeHandlerAfterEditAddress();
    this.deleteAddress();
    this.clickChangeHandlerButton();
  }


  init() {
    if(!this.deliveryContainer) return;
    this.addAddress();
    this.changeAddress()
  }
}

