'use strict';

export default class MainNav {
  constructor() {
    this.page = document.querySelector('.page');
    this.name = 'main-nav';
    this.innerMainNav = document.querySelector('.header');
    this.mainNav = document.querySelector('.main-nav');
    this.pageContent = document.querySelector('.page__content');
    this.burger = document.querySelector('.header__burger');
    this.mainNavLink = this.mainNav.querySelector('.main-nav__link');
    this.mainNavSubLink = this.mainNav.querySelector('.main-nav__sub-link');
    this.mainNavSubLinkSecond = this.mainNav.querySelector('.main-nav__sub-link-second');
    this.innerSubMenu = this.innerMainNav.querySelector('.main-nav__inner-sub-menu');
    this.innerSubMenuSecond = this.innerMainNav.querySelector('.main-nav__inner-sub-menu-second');
    this.filterCatalog = document.querySelector('.filter');
  }

  _toggleHeaderBgColor() {
    if (!this.innerMainNav) return;
    this.innerMainNav.classList.toggle('header--active');
    this.page.classList.toggle('header-menu-open');
  }

  _toggleMainMenu(evt) {
    if (this.mainNav) {
      evt.preventDefault();
      this.mainNav.classList.toggle('main-nav--active');
      this._toggleHeaderBgColor();
    }
  }

  _toggleLink(link, activeLinkCssClass) {
    const activeLink = '.' + activeLinkCssClass;
    if (link.classList.contains(activeLinkCssClass)) return;
    const activeLinkElement = document.querySelector(activeLink);
    if (activeLinkElement) {
      activeLinkElement.classList.remove(activeLinkCssClass)
    }
    link.classList.add(activeLinkCssClass);
  };

  _openSubMenu(linkElement, activeLinkCssClass) {
    if (linkElement.classList.contains(activeLinkCssClass) && linkElement.nextElementSibling) {
      const subMenuClone = linkElement.nextElementSibling.cloneNode(true);
      this.innerMainNav.classList.add('header--active');

      if (activeLinkCssClass === 'main-nav__link--active') {
        this.innerSubMenu.innerHTML = '';
        this.innerSubMenuSecond.innerHTML = '';
        this.innerSubMenu.innerHTML = subMenuClone.innerHTML;
      } else if (activeLinkCssClass === 'main-nav__sub-link--active') {
        this.innerSubMenuSecond.innerHTML = '';
        this.innerSubMenuSecond.innerHTML = subMenuClone.innerHTML;
      }
    } else {

      if (activeLinkCssClass === 'main-nav__link--active') {
        this.innerSubMenu.innerHTML = '';
      } else if (activeLinkCssClass === 'main-nav__sub-link--active') {
        this.innerSubMenuSecond.innerHTML = '';

      }

    }
  };


  _onMouseOverMainNavLink(evt) {
    if (!evt.target.closest('.header--active')) return;
    if (evt.target.closest('.main-nav__link')) {
      evt.preventDefault();
      const link = evt.target.closest('.main-nav__link');
      this._toggleLink(link, 'main-nav__link--active');

      this._openSubMenu(link, 'main-nav__link--active');
    }
  };

  _onMouseOverSabLink(evt) {
    if (evt.target.closest('.main-nav__sub-link')) {
      evt.preventDefault();
      const link = evt.target.closest('.main-nav__sub-link');
      this._toggleLink(link, 'main-nav__sub-link--active');

      this._openSubMenu(link, 'main-nav__sub-link--active');
    }
  }

  _onClickMainNavLink(evt) {
    if (evt.target.closest('.main-nav__link')) {
      evt.preventDefault();
      const link = evt.target.closest('.main-nav__link');
      this._toggleLink(link, 'main-nav__link--active');
    }
  };

  _onClickSubNavLink(evt) {
    if (evt.target.closest('.main-nav__sub-link')) {
      evt.preventDefault();
      const link = evt.target.closest('.main-nav__sub-link');
      this._toggleLink(link, 'main-nav__sub-link--active');
    }
  }

  _onClickSubLinkSecond(evt) {
    if (evt.target.closest('.main-nav__sub-link-second')) {
      evt.preventDefault();
      const link = evt.target.closest('.main-nav__sub-link-second');
      this._toggleLink(link, 'main-nav__sub-link-second--active');
    }
  };

  _disabledMainNav() {
    this.innerMainNav.classList.remove('header--active');
    this.mainNav.classList.remove('main-nav--active');
    this.page.classList.remove('header-menu-open');
  }

  _onMouseOverPageContent(evt) {
    if (evt.target.closest('.page__content')) {
      this._disabledMainNav();
    }
  }

  _onClickFilterCatalog(evt) {
    if (evt.target.closest('.filter')) {
      this._disabledMainNav();
    }
  }

  init() {
    if (this.burger) {
      this.burger.addEventListener('click', evt => this._toggleMainMenu(evt));
    }

    if (this.mainNavLink) {
      this.mainNav.addEventListener('click', evt => this._onClickMainNavLink(evt));
      this.mainNav.addEventListener('mouseover', evt => this._onMouseOverMainNavLink(evt));
    }

    if (this.mainNavSubLink) {
      this.mainNav.addEventListener('click', evt => this._onClickSubNavLink(evt));
      this.mainNav.addEventListener('mouseover', evt => this._onMouseOverSabLink(evt));
    }

    if (this.mainNavSubLinkSecond) {
      this.mainNav.addEventListener('click', evt => this._onClickSubLinkSecond(evt));
    }

    if (this.pageContent && this.innerMainNav) {
      this.pageContent.addEventListener('mouseout', evt => this._onMouseOverPageContent(evt));
    }

    if (this.filterCatalog) {
      document.addEventListener('click', evt => this._onClickFilterCatalog(evt));
    }
  }
}
