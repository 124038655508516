'use strict';

const DefaultClass = {
  linkClass: 'preview-product__tabs-link',
  activeClass: 'preview-product__tabs-link--active',
};


export default class PreviewProduct {
  constructor(elem) {
    this.previewProduct = elem;
    this.init();
  }

  previewProductClickHandler() {
    this.previewProduct.addEventListener('click', (evt) => {
      if (!evt.target.classList.contains(DefaultClass.linkClass)) return;
      this.changeActiveTab(evt);
    });
  }

  changeActiveTab(evt) {
    evt.preventDefault();
    if (evt.target.classList.contains(DefaultClass.activeClass)) return;
    this.previewProduct.querySelector(`.${DefaultClass.activeClass}`).classList.remove(DefaultClass.activeClass);
    evt.target.classList.add(DefaultClass.activeClass);
  }

  init() {
    this.previewProductClickHandler();
  }
}
