'use strict';

export default class Dropdown {
  constructor({
    toggleCssClass = '.dropdown__toggle',
    activeCssClass = 'dropdown__toggle--active',
    innerContent = '.dropdown__inner',
    callbackOpen,
    callbackClose,
    isCancellationClosureWhenClickingContentDropdown = false // если true содержимое активного dropdown(innerContent) не будет
    // скрываться по клику на на этом содержимом
  } = {}) {
    // this.name = 'dropdown';
    // console.log('%s module', this.name.toLowerCase());

    this.toggleCssClass = toggleCssClass;
    this.activeCssClass = activeCssClass;
    this.innerContent = innerContent;
    this.isCancellationClosureWhenClickingContentDropdown = isCancellationClosureWhenClickingContentDropdown;
    this.callbackOpen = callbackOpen;
    this.callbackClose = callbackClose;

    this.toggleDropdown();
  }

  removeActiveCssClassAllDropdown() {
    const arrActiveButtons = [...document.querySelectorAll('.' + this.activeCssClass)];
    if (arrActiveButtons.length > 0) arrActiveButtons.forEach(element => {
      element.classList.remove(this.activeCssClass)
    });
  }

  removeSiblingActiveButtonCssClass(activeButton) {
    const arrActiveButtons = [...document.querySelectorAll('.' + this.activeCssClass)];
    if (arrActiveButtons.length > 0) arrActiveButtons.forEach(element => {
      if (element !== activeButton) element.classList.remove(this.activeCssClass)
    });
  }



  toggleDropdown() {
    document.addEventListener('click', evt => {
      if (evt.target.closest(this.toggleCssClass)) {
        evt.preventDefault();
        evt.stopPropagation();
        const activeButton = evt.target.closest(this.toggleCssClass);
        this.removeSiblingActiveButtonCssClass(activeButton);
        activeButton.classList.toggle(this.activeCssClass);
        this.addCallback(evt.target);
      } else if (this.isCancellationClosureWhenClickingContentDropdown && evt.target.closest(this.innerContent)) {
        evt.stopPropagation();
      } else {
        this.removeActiveCssClassAllDropdown();
      }
    })
  }

  addCallback(dropdownElementButton) {
    if (dropdownElementButton.classList.contains(this.activeCssClass) && this.callbackOpen) {
      this.callbackOpen();
    } else if (this.callbackClose) this.callbackClose();
  }
}
