'use strict';

import Popup from '../popup/popup'
import PopupSlider from '../popup-slider/popup-slider'

export default class UiKit {
  constructor() {
    this.linkOpenPopup = document.querySelector('.ui-kit__open-popup-link');
    this.popup = document.querySelector('.popup');
    this.previewList = document.querySelector('.popup-slider-preview');
    this.viewPassword = document.querySelector('.view-password');

  }

  init() {

    if(this.linkOpenPopup) {
      const popup = new Popup({
        popupElement: this.popup
      });
      this.linkOpenPopup.addEventListener('click',(evt) => {
        evt.preventDefault();
        popup.openPopup(evt)
      });
    }

    if(this.previewList) {
      const popupSlider = new PopupSlider({
        linkOpenPopupCssClass: '.awards__link--slider-popup',
        listPreviewCssClass: '.awards__list--popup-slider-ui-kit',
        callback: ()=>console.log('---close popup slider:')
      });

      popupSlider.init();
    }
  }


}
