'use strict';
import Choices from 'choices.js'
import {uppercaseFirstSymbol} from '../_pages/commonFunctions'

export default class CustomSelect {
  constructor() {
    this.name = 'custom-select';
    // console.log('%s module', this.name.toLowerCase());

    this.selectCssClass =  '.custom-select';
    this.dropdownCssClass = '.choices__list--dropdown';
    this.dropdownLinkCssClass = 'choices__list--has-link';
    this.selectArr = [...document.querySelectorAll(this.selectCssClass)];
    this.selectInstanceArr = [];



    this.init();
  }

  addLink(selectInstance){
    const link = selectInstance.passedElement.element.dataset.link;
    const linkText =  selectInstance.passedElement.element.dataset.linkText;

    if(link && linkText) {
      const inner = selectInstance.passedElement.element.parentElement;
      setTimeout(()=>{
        const dropdown = inner.querySelector(this.dropdownCssClass);
        dropdown.classList.add(this.dropdownLinkCssClass);
        const linkElement = document.createElement('a');
        linkElement.classList.add('choices__link-more','link','link--open-popup');
        linkElement.setAttribute('href', link);
        linkElement.textContent = linkText;
        dropdown.appendChild(linkElement);
      },200)
    }
  }

  getTypeSelect(selectInstance) {
    return uppercaseFirstSymbol(selectInstance.passedElement.element.dataset.typeSelect);
  }

  init() {
    const that = this;
    const options = {
      searchEnabled: false,
      itemSelectText: '',
      placeholder: true,
      placeholderValue: ' выбрать',
      callbackOnCreateTemplates: function (template) {
        that.addLink(this);
        return {
          item: (classNames, data) => {
            return template(`
          <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}" data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
            <span>${that.getTypeSelect(this)}:</span> ${data.label}
          </div>
        `);
          },
          choice: (classNames, data) => {
            return template(`
          <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
             ${data.label}
          </div>
        `);
          },
        };
      }
    };

    if(this.selectArr.length > 0) {
     this.selectInstanceArr = new Choices(this.selectCssClass, options);
    }
  }
}
