'use strict';

import Swiper from "swiper";

export default class Slider {
  constructor() {
    this.name = 'slider';
    this.slider = document.querySelector('.slider');
  }


  _initSlider() {

    let slidePerView;
    if (window.matchMedia('(min-width: 992px)').matches) {
      slidePerView = 1.445;
    } else if (window.matchMedia('(min-width: 768px').matches) {
      slidePerView = 1.12;
    } else slidePerView = 1;

    const slider = new Swiper(this.slider, {
      direction: 'horizontal',
      spaceBetween: 0,
      slidesPerView: slidePerView,
      loop: false,
      navigation: {
        nextEl: '.slider__prev-button',
        prevEl: '.slider__next-button',
        disabledClass: 'display-visability-hidden'
      },
      containerModifierClass: 'slider',
      slideClass: 'slider__list-item',
      slideActiveClass: 'slider__list-item--active',
      slideVisibleClass: 'slider__list-item--visible',
      slideDuplicateClass: 'slider__list-item--duplicate-class',
      slideNextClass: 'slider__list-item--next-class',
      slideDuplicateNextClass: 'slider__list-item--duplicate-next-class',
      slidePrevClass: 'slider__list-item--prev-class',
      slideDuplicatePrevClass: 'slider__list-item--duplicate-prev-class',
      wrapperClass: 'slider__list-images',
    });
  }

  init() {
    this._initSlider();

  }
}
