"use strict";
const DeafultClass = {
  list: "order-filters",
  activeItem: "order-filters__item--active",
  item: "order-filters__item",
  listOpen: "order-filters--open"
};

export default class OrderFilters {
  constructor() {
    this.el = document.querySelector(`.${DeafultClass.list}`);
    // console.log('%s module', this.name.toLowerCase());
    this.activeItem = document.querySelector(`.${DeafultClass.activeItem}`);
    this.init();
  }

  toggleDropList() {
    this.el.addEventListener("click", evt => {
      if (document.documentElement.clientWidth > 780) return;
      if (!evt.target.closest(`.${DeafultClass.activeItem}`)) return;
      if (this.el.classList.contains(DeafultClass.listOpen)) {
        this.dropListClose();
      } else {
        this.dropListOpen();
      }
    });
  }

  changeActiveItem() {
    this.el.addEventListener("click", evt => {
      if (evt.target.closest(`.${DeafultClass.activeItem}`)) return;
      if (!evt.target.closest(`.${DeafultClass.item}`)) return;
      this.activeItem.classList.remove(DeafultClass.activeItem);
      this.activeItem = evt.target.closest(`.${DeafultClass.item}`);
      this.activeItem.classList.add(DeafultClass.activeItem);
      if (window.innerWidth > 780) return;
      this.el.insertBefore(this.activeItem, this.el.firstChild);
      this.dropListClose();
    });
  }

  dropListOpen() {
    this.el.classList.add(DeafultClass.listOpen);
  }
  dropListClose() {
    this.el.classList.remove(DeafultClass.listOpen);
  }

  init() {
    if (!this.el) return;
    this.toggleDropList();
    this.changeActiveItem();
  }
}
