export class AddCallbackResize {
  constructor({callback = (()=>{}), timeOut = 500} = {}) {
    this.name='AddCallbakcResize';
    console.log('%s module', this.name.toLowerCase());

    this.callback = callback;
    this.timeOut = timeOut;
    this.resizeTimeOut = null;

    this.init();
  }

  resizeThrottle() {
    if(!this.resizeTimeOut) {
      this.resizeTimeOut = setTimeout(()=>{
        this.resizeTimeOut = null;
        this.callback();
      }, this.timeOut);
    }
  }

  init() {
    window.addEventListener('resize', () => this.resizeThrottle(), false)
  }


}
export const uppercaseFirstSymbol = (str) => {

  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};