'use strict';

import Popup from '../popup/popup'
import PopupSlider from '../popup-slider/popup-slider'

export default class History {
  constructor() {
    this.linkOpenPopup = document.querySelector('.awards__link--slider-popup');
    this.popup = document.querySelector('.popup--slider-awards');
    this.previewList = document.querySelector('.awards__list--popup-slider');

    this.init();
  }

  addPopup() {
    if (this.linkOpenPopup) {
      const popup = new Popup({
        popupElement: this.popup
      });
      this.linkOpenPopup.addEventListener('click', (evt) => {
        evt.preventDefault();
        popup.openPopup(evt)
      });
    }
  }

  addSlider() {
    if (this.previewList) {
      const popupSlider = new PopupSlider({
        popupCssClass: '.popup--slider-awards',
        linkOpenPopupCssClass: '.awards__link--slider-popup',
        listPreviewCssClass: '.awards__list--popup-slider'
      });

      popupSlider.init();
    }
  }

  init() {
    this.addPopup();
    this.addSlider();
  }
}
