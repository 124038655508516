'use strict';

import Swiper from "swiper";

export default class MightLike {
  constructor() {
    this.name = 'might-like';
    // console.log('%s module', this.name.toLowerCase());
    //
    this.slider = document.querySelector('.might-like__inner-slider');
    this.sliderInstance = null;

    this.init();
  }

  init() {
    this.sliderInstance = new Swiper( this.slider , {
      direction: 'horizontal',
      spaceBetween: 0,
      slidesPerView: 1,
      loop: false,
      containerModifierClass: this.name,
      slideClass: 'might-like__list-item',
      slideActiveClass: 'might-like__list-item--active',
      slideVisibleClass: 'might-like__list-item--visible',
      slideDuplicateClass: 'might-like__list-item--duplicate-class',
      slideNextClass: 'might-like__list-item--next-class',
      slideDuplicateNextClass: 'might-like__list-item--duplicate-next-class',
      slidePrevClass: 'might-like__list-item--prev-class',
      slideDuplicatePrevClass: 'might-like__list-item--duplicate-prev-class',
      wrapperClass: 'might-like__list-images',
      pagination: {
        clickable: true,
        el: '.might-like__pagination',
        type: 'bullets',
        bulletClass: 'might-like__pagination-link',
        bulletActiveClass: 'might-like__pagination-link--active'
      },
      navigation: {
        nextEl: '.button--next',
        prevEl: '.button--prev',
        disabledClass: 'display-visability-hidden',
        hiddenClass: 'display-visability-hidden'
      },
      breakpointsInverse: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20
        },
      }
    });
  }
}
