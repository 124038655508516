'use strict';

export default class Collapse {
  constructor({
                switchCssClass='.collapse__switch',
                switchCssClassActive='collapse__switch--active',
                callbackOpen,
                callbackClose
              }={}) {
    this.switchCssClass = switchCssClass;
    this.switchCssClassActive = switchCssClassActive;
    this.callbackOpen = callbackOpen;
    this.callbackClose = callbackClose;
    this.toggleCollapse();
  }

  toggleCollapse(){
    document.addEventListener('click', evt => {
      if(!evt.target.closest(this.switchCssClass)) return;
      evt.preventDefault();
      const switchElement = evt.target.closest(this.switchCssClass);
      switchElement.classList.toggle(this.switchCssClassActive);
      this.addCallback(switchElement);
    })
  }

  addCallback(switchElement) {
    if(switchElement.classList.contains(this.switchCssClassActive) && this.callbackOpen) {
      this.callbackOpen();
    } else if(this.callbackClose) this.callbackClose();
  }

}
