'use strict';

const DefaultClass = {
  active: 'popup--active'
};

export default class PopUps {
  constructor() {
    this.html = document.querySelector('html');
    this.servicesPopups = document.querySelectorAll('.popup--service');
    this.enterPopupBtn = document.querySelectorAll('.pop-up-btn--enter');
    this.enterPopup = document.querySelector('.popup--enter');
    this.questionPopupBtn = document.querySelectorAll('.contacts__ask-question');
    this.questionPopup = document.querySelector('.popup--question');
    this.registrationPopupBtn = document.querySelector('.registration-btn');
    this.registrationPopup = document.querySelector('.popup--registration');
    this.forgetPopupBtn = document.querySelector('.forget-btn');
    this.forgetPopup = document.querySelector('.popup--forget');
    this.formPass = document.querySelector('.form__row--password');
    this.formPassInput = this.formPass.querySelector('.input--password');
    this.init();
  }

  eyeButtonClickHandler() {
    this.formPass.addEventListener('click', (evt) => {
      if (!evt.target.closest('.view-password')) return;
      if (this.formPassInput.type === 'password') {
        this.formPassInput.type = 'text';
      } else if (this.formPassInput.type === 'text') {
        this.formPassInput.type = 'password';
      }
    });
  }


  buttonsClickHandler() {
    this.forgetPopupBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.openModal(this.forgetPopup);
    });

    this.registrationPopupBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.openModal(this.registrationPopup);
    });

    this.enterPopupBtn.forEach((item) => {
      item.addEventListener('click', (evt) => {
        evt.preventDefault();
        this.openModal(this.enterPopup);
      });
    });

    this.questionPopupBtn.forEach((item) => {
      item.addEventListener('click', (evt) => {
        evt.preventDefault();
        this.openModal(this.questionPopup);
      });
    });
  }

  openModal(modal) {
    this.html.style.overflow = 'hidden';
    this.servicesPopups.forEach((item) => {
      item.classList.remove(`${DefaultClass.active}`)
    });
    modal.classList.add(`${DefaultClass.active}`);
    modal.querySelector('.popup__close').addEventListener('click', () => {
      this.closeModal(modal);
    });
    modal.addEventListener('click', (evt) => {
      if (evt.target !== modal) return;
      this.closeModal(modal);
    });
    document.addEventListener('keydown', (evt) => {
      if (evt.keyCode === 27) {
        this.closeModal(modal);
      }
    });
  }

  closeModal(modal) {
    this.html.style.overflow = '';
    modal.classList.remove(`${DefaultClass.active}`);
  }


  init() {
    this.buttonsClickHandler();
    this.eyeButtonClickHandler();
  }
}
