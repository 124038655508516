"use strict";
const DeafultClass = {
  list: "map__nav",
  item: "map__nav-link",
  activeItem: "map__nav-link--active"
};

export default class Map {
  constructor() {
    this.name = "map";
    // console.log('%s module', this.name.toLowerCase());
    this.list = document.querySelector(`.${DeafultClass.list}`);
    this.activeItem = document.querySelector(`.${DeafultClass.activeItem}`);
    this.init();
    this.newData = [];
    this.myMap;
  }

  interactiveMap() {
    ymaps.ready(init.bind(this));

    function init() {
      // Создание карты.
      var myMap = new ymaps.Map("map", {
        // Координаты центра карты.
        // Порядок по умолчанию: «широта, долгота».
        // Чтобы не определять координаты центра карты вручную,
        // воспользуйтесь инструментом Определение координат.
        center: [55.76, 37.64],
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: 10
      });
      this.myMap = myMap;
      myMap.events.add("click", function() {
        myMap.balloon.close();
      });

      fetch("/towns.json")
        .then(response => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          } // Examine the text in the response
          response.json().then(data => this.prepareJson(data));
        })
        .catch(err => {
          console.log("Fetch Error :-S", err);
        });
    }
  }

  prepareJson(data) {
    data.map(place => {
      const newPlace = {};
      newPlace.phone = place.phone.map(
        phone =>
          `<a href="tel:${phone}" class="baloon-footer__phone">${phone}</a>`
      );
      newPlace.email = place.email.map(
        email =>
          `<a href="mailto:${email}" class="baloon-footer__mail">${email}</a>`
      );
      let placemark = new ymaps.Placemark(
        place.coordinates,
        {
          balloonContentHeader: `<p class='baloon-header'>${place.name}</p>`,
          balloonContentBody: `<div class='baloon-body'>
        <p class='baloon-body__adress'>${place.adress}</p>
        <p class='baloon-body__director'>${place.director}</p>
        <p class='baloon-body__worktime'>${place.worktime}</p>
        </div>`,
          balloonContentFooter: `<div class='baloon-footer'>
        <p class='baloon-footer__phones'>${newPlace.phone}</p>
        <p class='baloon-footer__mails'>${newPlace.email}</p>
        </div>`
        },
        {
          iconLayout: "default#image",
          iconImageHref: "../images/svg/Placemark.svg",
          iconImageSize: [32, 32]
        }
      );
      this.newData[place.id] = {
        data: placemark,
        type: place.type
      };
      this.myMap.geoObjects.add(placemark);
    });
  }

  addMapScriptonPage() {
    var elemMapScript = document.createElement("script");
    elemMapScript.src =
      "https://api-maps.yandex.ru/2.1/?apikey=1e16b2ae-d9d7-4e14-a633-955b95492282&lang=ru_RU";
    document.body.appendChild(elemMapScript);
    elemMapScript.onload = () => {
      this.interactiveMap();
    };
  }

  changeActiveTab() {
    this.list.addEventListener("click", evt => {
      evt.preventDefault();
      if (evt.target.classList.contains(`${DeafultClass.activeItem}`)) return;
      if (!evt.target.classList.contains(`${DeafultClass.item}`)) return;
      this.changeMap(evt.target);
      this.activeItem.classList.remove(DeafultClass.activeItem);
      this.activeItem = evt.target;
      this.activeItem.classList.add(DeafultClass.activeItem);
    });
  }

  filterByType(type) {
    this.newData.forEach(placemark => {
      placemark.data.options.set("visible", true);
      if (placemark.type !== type) {
        placemark.data.options.set("visible", false);
      }
    });
  }

  changeMap(item) {
    this.myMap.balloon.close();

    if (item.id == "all-shops") {
      this.newData.forEach(placemark => {
        placemark.data.options.set("visible", true);
      });
    } else if (item.id == "only-shops") {
      this.filterByType("shop");
    } else if (item.id == "only-filials") {
      this.filterByType("filiation");
    }
  }

  init() {
    this.addMapScriptonPage();
    this.changeActiveTab();
  }
}
