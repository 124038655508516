'use strict';
import Swiper from "swiper";


export default class ProductSlider {
  constructor() {
    this.name = 'product-slider';
    // console.log('%s module', this.name.toLowerCase());

    this.sliderInner = document.querySelector('.product-slider');
    this.sliderElement = this.sliderInner.querySelector('.product-slider__images');
    this.thumbsElement = this.sliderInner.querySelector('.product-slider__thumbs');
    this.imgCssClass = '.product-slider__img';
    this.itemCssClass = '.product-slider__item';
    this.sliderInstance = null;
    this.thumbsInstance = null;

    this.init();
  }

  getElementWidth(selector){
    return document.querySelector(selector).offsetWidth;
  }

  changeSizeImage(imgSelector, width, height,callback) {
    const imgArr = [...this.sliderElement.querySelectorAll(imgSelector)];
    width = width ? width+'px' : 'auto';
    height = height ? height+'px' : 'auto';

    imgArr.forEach(element => {
        element.style.cssText = `
        width: ${width}; 
        height: ${height};
      `;
    });

    if(callback) callback();
  }

  addSlider({isPagination=false, isNavigation=false, isThumbnails=false}={}) {

    const pagination = isPagination ? {
      clickable: true,
      el: '.product-slider__pagination',
      type: 'bullets',
      bulletClass: 'product-slider__pagination-link',
      bulletActiveClass: 'product-slider__pagination-link--active'
    } : false;

    const navigation = isNavigation ? {
      nextEl: '.button--next',
      prevEl: '.button--prev',
      disabledClass: 'display-visability-hidden',
      hiddenClass: 'display-visability-hidden'
    } : false;

    if (isThumbnails) {
      this.thumbsInstance = new Swiper(this.thumbsElement, {
        setWrapperSize: true,
        spaceBetween: 10,
        slidesPerView: 5,
        loop: false,
        freeMode: false,
        direction: 'vertical',
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: navigation,
        mousewheel: {
          invert: true,
        },

        containerModifierClass: this.name + '-thumbnails',
        slideClass: 'product-slider__thumbs-item',
        slideActiveClass: 'product-slider__thumbs-item-active',
        slideVisibleClass: 'product-slider__thumbs-item--visible',
        slideDuplicateClass: 'product-slider__thumbs-item--duplicate-class',
        slideNextClass: 'product-slider__thumbs-item--next-class',
        slideDuplicateNextClass: 'product-slider__thumbs-item--duplicate-next-class',
        slidePrevClass: 'product-slider__thumbs-item--prev-class',
        slideDuplicatePrevClass: 'product-slider__thumbs-item--duplicate-prev-class',
        wrapperClass: 'product-slider__list-thumbs',
      })
    }


    this.sliderInstance = new Swiper( this.sliderElement , {
      setWrapperSize: true,
      effect: 'fade',
      spaceBetween: 0,
      slidesPerView: 1,
      loop: false,
      loopedSlides: 5,
      containerModifierClass: this.name ,
      slideClass: 'product-slider__item',
      slideActiveClass: 'product-slider__item--active',
      slideVisibleClass: 'product-slider__item--visible',
      slideDuplicateClass: 'product-slider__list-item--duplicate-class',
      slideNextClass: 'product-slider__item--next-class',
      slideDuplicateNextClass: 'product-slider__item--duplicate-next-class',
      slidePrevClass: 'product-slider__item--prev-class',
      slideDuplicatePrevClass: 'product-slider__item--duplicate-prev-class',
      wrapperClass: 'product-slider__list-images',
      pagination: pagination,

      on: {
        init: () => {
          this.sliderInner.classList.add('product-slider--init')
        }
      },
      thumbs: {
        swiper: this.thumbsInstance,
        slideThumbActiveClass: 'product-slider__thumbs-item--active'
      },
      fadeEffect: {
        crossFade: true
      }
    });
  }

  init() {
    if(window.matchMedia("(max-width: 1199px)").matches) {
      this.addSlider({isPagination:true});
    }
    if(window.matchMedia("(min-width:1200px)").matches) {
      this.addSlider({isNavigation:true, isThumbnails: true});
    }
  }
}
