'use strict';

import {
  log
} from "util";

export default class Popup {
  constructor({
    popupElement, // dom попапа
    customCloseButtonCssClass, // CSS класс кастомной кнопки закрытия
    callbackBeforeOpen, // калбек вызываемый до открыитя попапа
    callbackAfterOpen, // калбек вызываемый после открыитя попапа
    callbackBeforeClose, // калбек вызываемый до закрытия попапа
    callbackAfterClose, // калбек вызываемый после закрытия попапа
    isNotClosePopupAfterClickBackground = false, // ставим true что бы не закрывать попап при клике вне контета
    popupOpenLinkCssClass
  }) {
    this.popupElements = popupElement;
    this.popupCssClass = 'popup';
    this.popupCloseButtonCssClass = 'popup__close';
    this.customCloseButtonCssClass = customCloseButtonCssClass;
    this.callbackBeforeOpen = callbackBeforeOpen;
    this.callbackAfterOpen = callbackAfterOpen;
    this.callbackBeforeClose = callbackBeforeClose;
    this.callbackAfterClose = callbackAfterClose;
    this.isNotClosePopupAfterClickBackground = isNotClosePopupAfterClickBackground;
    this.popupOpenLinkCssClass = popupOpenLinkCssClass;

    this.popupsArr = [];

    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.closeByEsc = this.closeByEsc.bind(this);
    this.clickCloseButtonHandler = this.clickCloseButtonHandler.bind(this);

    this.init();
  }

  isEscPressed(evt) {
    return evt.keyCode === 27;
  };

  closeByEsc(evt) {
    if (this.isEscPressed(evt)) {
      this.closePopup();
    }
  };

  clickCloseButtonHandler(evt) {
    if (evt.target.closest('.' + this.popupCloseButtonCssClass) ||
      evt.target.closest('.' + this.customCloseButtonCssClass) ||
      evt.target.closest('.' + this.popupCssClass) && !this.isNotClosePopupAfterClickBackground
    ) {
      evt.preventDefault();
      this.closePopup();
    }
  };

  addEvtHandlers() {
    document.addEventListener('click', this.clickCloseButtonHandler);
    window.addEventListener('keydown', this.closeByEsc);
  };

  removeHandlers() {
    document.removeEventListener('click', this.clickCloseButtonHandler);
    window.removeEventListener('keydown', this.closeByEsc);
  };

  openPopup() {
    this.addEvtHandlers();
    if (!this.popupElements) return;

    document.querySelector('html').style.overflow = "hidden";
    if (this.callbackBeforeOpen) this.callbackBeforeOpen();

    //если Popup создается для конкретного попапа
    this.popupElements.classList.add('popup--active');

    if (this.callbackAfterOpen) this.callbackAfterOpen();
  };

  closePopup() {
    this.removeHandlers();
    if (!this.popupElements) return;
    document.querySelector('html').style.overflow = "";
    if (this.callbackBeforeClose) this.callbackBeforeClose();


    this.popupElements.classList.remove('popup--active');


    if (this.callbackAfterClose) this.callbackAfterClose();
  };


  init() {
    if (this.popupOpenLinkCssClass) {
      document.addEventListener('click', evt => {
        if (evt.target.closest(this.popupOpenLinkCssClass)) {
          evt.preventDefault();
          this.popupElements = document.querySelector(evt.target.closest(this.popupOpenLinkCssClass).getAttribute('href'));
          this.openPopup();
        }

      });
    }
  }

}
