'use strict';
import Dropdown from '../dropdown/dropdown';

export default class FilterCategory {
  constructor() {
    this.name = 'filter-category';
    // console.log('%s module', this.name.toLowerCase());

    this.toggleCssClass = '.filter__toggle-dropdown';
    this.activeCssClass = 'filter__toggle-dropdown--active';
    this.innerContent = '.filter__inner-toggle-content';
    this.closeFilterButton = '.filter__close-button';
    this.dropdown = null;

    this.init();
  }

  closeFilters() {
    document.addEventListener('click', evt => {
      if (evt.target.closest(this.closeFilterButton)) {
        evt.preventDefault();
        document.querySelector('.header').classList.remove('header--open-filter');
        this.dropdown.removeActiveCssClassAllDropdown();
        this.addScrollDocument();
      }
    });
  }

  hiddenScrollDocumentForMobile() {
    if (window.matchMedia("(max-width: 1200px)").matches) {
      document.querySelector('html').style.overflow = "hidden";
      document.querySelector('.header').classList.add('header--open-filter');
    }
  }

  addScrollDocument() {
    document.querySelector('html').style.overflow = "";
  }

  addDropdown() {
    this.dropdown = new Dropdown({
      toggleCssClass: this.toggleCssClass,
      activeCssClass: this.activeCssClass,
      innerContent: this.innerContent,
      isCancellationClosureWhenClickingContentDropdown: true,
      callbackOpen: this.hiddenScrollDocumentForMobile,
      callbackClose: this.addScrollDocument
    });
  }

  init() {
    this.addDropdown();
    this.closeFilters();
  }
}
