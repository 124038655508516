'use strict';

import Swiper from "swiper";

export default class OrderGoods {
  constructor(goods) {
    this.orderGoods = goods.querySelector('.order-goods__inner');
  }

  _initOrderGoods() {
    this.swiper = new Swiper(this.orderGoods, {
      wrapperClass: 'order-goods__list-outer',
      slideClass: 'order-goods__list',
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      mousewheel: true
    });
  }

  init() {
    this._initOrderGoods();
  }
}
