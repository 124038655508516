'use strict';

import PopupSlider from '../popup-slider/popup-slider'

export default class ProductGallery {
  constructor() {
    this.popupCssClass = '.popup--slider-product';
    this.linkOpenPopupCssClass = '.product-slider__item';
    this.listPreviewCssClass = '.product-slider__list-images';
  }

  init() {
    const popupSlider = new PopupSlider({
      popupCssClass: this.popupCssClass,
      linkOpenPopupCssClass: this.linkOpenPopupCssClass,
      listPreviewCssClass: this.listPreviewCssClass
    });

    popupSlider.init();
  }
}
