'use strict';

import OrderGoods from '../../_modules/order-goods/order-goods';

const DefaultClass = {
  orderOuter: "page__order-info",
  order: "profile-order",
  orderOpen: "profile-order--open",
  orderHeader: "profile-order__header",
  outer: "profile-order__content",
  container: "order-info",
  containerOpen: "order-info--open",
  toggle: "order-info__header"
};

export default class ProfileOrder {
  constructor() {
    this.orderOuter = document.querySelector(`.${DefaultClass.orderOuter}`);
    // console.log('%s module', this.name.toLowerCase());
    this.init();
  }

  toggleOrder() {
    this.orderOuter.addEventListener("click", evt => {
      if (!evt.target.closest(`.${DefaultClass.orderHeader}`)) return;
      const order = evt.target.closest(`.${DefaultClass.order}`);
      if (order.classList.contains(DefaultClass.orderOpen)) {
        this.containerClose(order, DefaultClass.orderOpen);
      } else {
        this.containerOpen(order, DefaultClass.orderOpen);
        const orderGoods = new OrderGoods(order.querySelector('.order-goods'));
        orderGoods.init();
      }
    });
  }

  toggleContainer() {
    this.orderOuter.addEventListener("click", evt => {
      if (window.innerWidth > 780) return;
      if (!evt.target.classList.contains(DefaultClass.toggle)) return;
      const container = evt.target.closest(`.${DefaultClass.container}`);
      if (container.classList.contains(DefaultClass.containerOpen)) {
        this.containerClose(container, DefaultClass.containerOpen);
      } else {
        this.containerOpen(container, DefaultClass.containerOpen);
      }
    });
  }

  containerOpen(item, activeClass) {
    item.classList.add(activeClass);
  }

  containerClose(item, activeClass) {
    item.classList.remove(activeClass);
  }

  init() {
    if (!this.orderOuter) return;
    this.toggleContainer();
    this.toggleOrder();
  }
}
