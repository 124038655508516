'use strict';

import Choice from 'choices.js';
import ProductSlider from '../product-slider/product-slider';

export default class Product {
  constructor() {
    this.innerElement = document.querySelector('.product');
    this.slider = null;
    this.init();
  }


  addSlider() {
    this.slider = new ProductSlider();
  }

  init() {
    this.addSlider();
  }
}
