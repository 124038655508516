'use strict';

const DefaultClass = {
  activeclass: 'second-nav__link--active'
};

export default class SecondNav {
  constructor() {
    this.secondNav = document.querySelector('.second-nav');
    this.navLinks = document.querySelectorAll('.second-nav__link');
    this.init();
  }

  sidebarHandler() {
    this.secondNav.addEventListener('click', (evt) => {
      if (!evt.target.classList.contains('second-nav__link')) return;
      evt.preventDefault();
      if (evt.target.classList.contains(DefaultClass.activeclass)) {
        evt.target.classList.remove(DefaultClass.activeclass);
      } else {
        evt.target.classList.add(DefaultClass.activeclass);
      }
    });
  }

  init() {
    if (!this.secondNav) return;
    this.sidebarHandler();
  }
}
