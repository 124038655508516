'use strict';

import Swiper from "swiper";

export default class Slider {
  constructor() {
    this.name = 'slider-in';
    this.slider = document.querySelector('.slider-in');

    this.init();
  }

  init() {
    const slider = new Swiper( this.slider , {
      direction: 'horizontal',
      spaceBetween: 0,
      slidesPerView: 1,
      loop: false,
      containerModifierClass: this.name,
      slideClass: 'slider-in__list-item',
      slideActiveClass: 'slider-in__list-item--active',
      slideVisibleClass: 'slider-in__list-item--visible',
      slideDuplicateClass: 'slider-in__list-item--duplicate-class',
      slideNextClass: 'slider-in__list-item--next-class',
      slideDuplicateNextClass: 'slider-in__list-item--duplicate-next-class',
      slidePrevClass: 'slider-in__list-item--prev-class',
      slideDuplicatePrevClass: 'slider-in__list-item--duplicate-prev-class',
      wrapperClass: 'slider-in__list-images',
      pagination: {
        clickable: true,
        el: '.slider-in__pagination',
        type: 'bullets',
        bulletClass: 'slider-in__pagination-link',
        bulletActiveClass: 'slider-in__pagination-link--active'
      },
      navigation: {
        nextEl: '.button--next',
        prevEl: '.button--prev',
        disabledClass: 'display-visability-hidden',
        hiddenClass: 'display-visability-hidden'
      },
    });
  }
}
