// Main javascript entry point
'use strict';

//modules:
import MainNav from '../_modules/main-nav/main-nav'
import PageSlider from '../_modules/page-slider/page-slider'
import Slider from '../_modules/slider/slider'
import InnerPageSlider from '../_modules/slider-in/slider-in'
import UiKit from '../_modules/ui-kit/ui-kit'
import Collapse from '../_modules/collapse/collapse'
import Dropdown from '../_modules/dropdown/dropdown'
import Delivery from '../_modules/delivery/delivery'
import Popup from '../_modules/popup/popup'
import CustomSelect from '../_modules/custom-select/custom-select'
import FilterCategory from '../_modules/filter-category/filter-category'
import ViewPassword from '../_modules/view-password/view-password'
import MightLike from '../_modules/might-like/might-like'
import ChangeAmt from '../_modules/change-amt/change-amt'
import OrderFilters from '../_modules/order-filters/order-filters'
import ProfileOrder from '../_modules/profile-order/profile-order'
import Header from '../_modules/header/header'
import Map from '../_modules/map/map'
import Popups from '../_modules/pop-ups/pop-ups'
import Forms from '../_modules/forms/forms'
import SecondNav from '../_modules/second-nav/second-nav'
import PreviewProduct from '../_modules/preview-product/preview-product'


//pages:
import History from '../_modules/_pages/history'
import Product from '../_modules/_pages/product'
import ProductGallery from '../_modules/_pages/product-gallery'

class Main {
  constructor() {
    this.uiKit = document.querySelector('.ui-kit');
    this.mainNav = document.querySelector('.main-nav');
    this.pageSlider = document.querySelector('.page-slider');
    this.footerSlider = document.querySelector('.slider');
    this.innerPageSlider = document.querySelector('.slider-in');
    this.delivery = document.querySelector('.delivery');
    this.categoryFilter = document.querySelector('.filter');
    this.pageProduct = document.querySelector('.product');
    this.mightLikeSlider = document.querySelector('.might-like');
    this.changeAmt = document.querySelector('.change-amt');
    this.customSelect = document.querySelector('.custom-select');
    this.orderFilters = document.querySelector('.order-filters');
    this.profileOrder = document.querySelector('.profile-order');
    this.header = document.querySelector('.header');
    this.map = document.querySelector('#map');
    this.popup = document.querySelector('.popup');
    this.form = document.querySelector('.input--phone');
    this.secondNav = document.querySelector('.second-nav');
  }

  init() {
    if (this.uiKit) {
      const uiKit = new UiKit();
      uiKit.init();
    }

    if (this.mainNav) {
      const mainNav = new MainNav();
      mainNav.init();
    }

    if (this.pageSlider) {
      const pageSlider = new PageSlider();
      pageSlider.init();

    }

    if (this.footerSlider) {
      const footerSlider = new Slider();
      footerSlider.init();
    }

    if (this.innerPageSlider) {
      const innerPageSlider = new InnerPageSlider();
    }

    if (this.categoryFilter) {
      const filterCategory = new FilterCategory();
    }

    const pageHistory = new History();
    const collapse = new Collapse();
    const dropDown = new Dropdown();
    const viewPassword = new ViewPassword();
    const popup = new Popup({
      popupOpenLinkCssClass: '.link--open-popup'
    });

    if (this.delivery) {
      const delivery = new Delivery();
    }

    if (this.pageProduct) {
      const pageProduct = new Product();
      const gallery = new ProductGallery();
      gallery.init();
    }

    if (this.mightLikeSlider) {
      const mightLikeSlider = new MightLike();
    }

    if (this.changeAmt) {
      const changeAmt = new ChangeAmt();
    }

    if (this.customSelect) {
      const customSelect = new CustomSelect();
    }

    if (this.orderFilters) {
      const orderFilters = new OrderFilters();
    }

    if (this.profileOrder) {
      const profileOrder = new ProfileOrder();
    }

    if (this.header) {
      const header = new Header();
    }

    if (this.map) {
      const map = new Map();
    }

    if (this.popup) {
      const popup = new Popups();
    }

    if (this.form) {
      const form = new Forms();
    }

    if (this.secondNav) {
      const secondNav = new SecondNav();
    }


  }
}

document.addEventListener('DOMContentLoaded', () => {
  const main = new Main();
  main.init();
});

document.querySelectorAll('.preview-product').forEach((elem) => {
  new PreviewProduct(elem);
});
