"use strict";
import Swiper from "swiper";

export default class PageSlider {
  constructor() {
    this.name = "page-slider";
    this.slider = document.querySelector(".page-slider");
    this.sliderThumbs = document.querySelector(".page-slider-thumbs");
    this.sliderDescription = document.querySelector(".page-slider-description");
  }

  _changeDescription(descriptionElement, descriptionSlide = "no description") {
    descriptionElement.innerHTML = "";
    descriptionElement.innerHTML = descriptionSlide.querySelector(
      ".page-slider__description-item"
    ).innerHTML;
  }

  _changeButtonsNavClass(activeSlideIndex, lengthSlide) {
    const prevButtonsArr = [...document.querySelectorAll(".button--prev")];
    const nextButtonsArr = [...document.querySelectorAll(".button--next")];

    console.log("--- activeIndex:", activeSlideIndex);
    console.log("--- lengthSlide:", lengthSlide);

    switch (activeSlideIndex) {
      case 0:
        prevButtonsArr.forEach(button => {
          button.classList.remove("button--third-last-but-one");
          button.classList.add("button--third-last");
        });
        break;
      case 1:
        prevButtonsArr.forEach(button => {
          button.classList.remove("button--third-last");
          button.classList.add("button--third-last-but-one");
        });
        break;
      case lengthSlide - 1:
        nextButtonsArr.forEach(button => {
          button.classList.remove("button--third-last-but-one");
          button.classList.add("button--third-last");
        });
        break;
      case lengthSlide - 2:
        nextButtonsArr.forEach(button => {
          button.classList.remove("button--third-last");
          button.classList.add("button--third-last-but-one");
        });
        break;
    }
  }

  _initSlider() {
    const sliderDescription = this.sliderDescription;
    const _changeDescription = this._changeDescription;
    const _changeButtonsNavClass = this._changeButtonsNavClass;

    let tumbsParams;
    if (window.matchMedia("(min-width: 1200px)").matches) {
      tumbsParams = {
        swiper: {
          el: ".page-slider-thumbs",
          slideThumbActiveClass: "page-slider-thumbs__slide",
          spaceBetween: 4,
          slidesPerView: 2,
          simulateTouch: false
        }
      };
    } else {
      tumbsParams = {};
    }

    const slider = new Swiper(this.slider, {
      direction: "vertical",
      loop: false,
      pagination: {
        el: ".page-slider__pagination",
        bulletClass: "page-slider__pagination-link",
        bulletActiveClass: "page-slider__pagination-link--active",
        clickable: true
      },
      navigation: {
        nextEl: ".button--prev",
        prevEl: ".button--next",
        disabledClass: "display-visability-hidden",
        hiddenClass: "display-visability-hidden"
      },
      slideClass: "swiper-slide",
      slideActiveClass: "page-slider__list-item--active",
      slideVisibleClass: "page-slider__list-item--visible",
      slideDuplicateClass: "page-slider__list-item--duplicate-class",
      slideNextClass: "page-slider__list-item--next-class",
      slideDuplicateNextClass: "page-slider__list-item--duplicate-next-class",
      slidePrevClass: "page-slider__list-item--prev-class",
      slideDuplicatePrevClass: "page-slider__list-item--duplicate-prev-class",
      wrapperClass: "swiper-wrapper",
      thumbs: tumbsParams,

      on: {
        init: function () {
          _changeDescription(sliderDescription, this.slides[this.activeIndex]);
          // _changeButtonsNavClass(this.activeIndex, this.slides.length);
        },
        slideChange: function () {
          _changeDescription(sliderDescription, this.slides[this.activeIndex]);
          // _changeButtonsNavClass(this.activeIndex, this.slides.length);
        },
        reachEnd: function () {
          document.querySelectorAll(".button--next").forEach(function (item) {
            item.classList.add("slide-end");
          });
        },
        reachBeginning: function () {
          document.querySelectorAll(".button--prev").forEach(function (item) {
            item.classList.add("slide-start");
          });
        },
        fromEdge: function () {
          document.querySelectorAll(".button--next").forEach(function (item) {
            item.classList.remove("slide-end");
          });
          document.querySelectorAll(".button--prev").forEach(function (item) {
            item.classList.remove("slide-start");
          });
        }
      }
    });
  }

  init() {
    this._initSlider();
  }
}
