'use strict';

import {
  log
} from "util";

export default class ChangeAmt {
  constructor() {
    this.name = 'change-amt';
    // console.log('%s module', this.name.toLowerCase());

    this.innerCssClass = '.change-amt';
    this.decreaseQtyButtonCssClass = '.change-amt__button--minus';
    this.increaseQtyButtonCssClass = '.change-amt__button--plus';
    this.inputCssClass = '.change-amt__input';
    this.qty = 1;

    this.inner = null;
    this.decreaseQtyButton = null;
    this.increaseQtyButton = null;
    this.input = null;
    this.valueElement = null;
    this.init();
  }

  isValidate(newValue) {
    const maxValue = this.input.getAttribute('max') ? this.input.getAttribute('max') : 100;
    const minValue = this.input.getAttribute('min') ? this.input.getAttribute('min') : 1;
    return newValue <= maxValue && newValue >= minValue;
  }

  activeButtons() {
    this.increaseQtyButton.disabled = false;
    this.decreaseQtyButton.disabled = false;
  }

  increaseQty(shiftKey) {
    const newValue = shiftKey ? this.qty + 10 : this.qty + 1;

    if (this.isValidate(newValue)) {
      this.qty = newValue;
      this.input.setAttribute('value', this.qty);
      this.input.value = this.qty;
      this.valueElement.textContent = this.qty;
      this.activeButtons();
    } else if (shiftKey && this.isValidate(this.qty + 1)) {
      //eсли зажата кнопка shift но превышается максимальное значение, кнопку не дизэйблим
      this.activeButtons();
    } else {
      this.increaseQtyButton.disabled = true;
    }
  }

  decreaseQty(shiftKey) {
    const newValue = shiftKey ? this.qty - 10 : this.qty - 1;

    if (this.isValidate(newValue)) {
      this.qty = newValue;
      this.input.setAttribute('value', this.qty);
      this.input.value = this.qty;
      this.valueElement.textContent = this.qty;
      this.activeButtons();
    } else if (shiftKey && this.isValidate(this.qty - 1)) {
      this.activeButtons();
    } else {
      this.decreaseQtyButton.disabled = true;
    }
  }

  clickIncreaseQtyButtonHandler(evt) {
    if (evt.target.closest(this.increaseQtyButtonCssClass) || evt.target.closest(this.decreaseQtyButtonCssClass)) {
      evt.preventDefault();

      this.inner = evt.target.closest(this.innerCssClass);
      this.increaseQtyButton = this.inner.querySelector(this.increaseQtyButtonCssClass);
      this.decreaseQtyButton = this.inner.querySelector(this.decreaseQtyButtonCssClass);
      this.input = this.inner.querySelector(this.inputCssClass);

      this.valueElement = this.inner.querySelector(this.inputCssClass);

      if (evt.target.closest(this.increaseQtyButtonCssClass)) this.increaseQty(evt.shiftKey);
      if (evt.target.closest(this.decreaseQtyButtonCssClass)) this.decreaseQty(evt.shiftKey);

    }
  }

  keyboardFocus(evt) {
    evt.target.value = 0;
  }

  keyboardBlur(evt) {
    if (evt.target.value == 0) {
      evt.target.value = this.qty;
    }
  }

  keyboardInputValue(evt) {
    const newValue = parseInt(evt.target.value);
    if (newValue >= evt.target.getAttribute('max')) {
      evt.target.value = evt.target.getAttribute('max');
    } else if (newValue <= evt.target.getAttribute('min')) {
      evt.target.value = evt.target.getAttribute('min');
    }
    evt.target.value = evt.target.value.replace(/^0+/, '');
    this.qty = parseInt(evt.target.value);
  }


  init() {
    document.addEventListener('click', evt => {
      this.clickIncreaseQtyButtonHandler(evt);
    });

    document.querySelectorAll('.change-amt__input').forEach((input) => {
      input.addEventListener('focus', evt => this.keyboardFocus(evt));
    });

    document.querySelectorAll('.change-amt__input').forEach((input) => {
      input.addEventListener('blur', evt => this.keyboardBlur(evt));
    });

    document.addEventListener('keyup', evt => {
      if (!evt.target.classList.contains('change-amt__input')) return;
      this.keyboardInputValue(evt);
    });
  }
}
